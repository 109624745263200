<template>
  <div ref="container">
    <vs-alert v-if="success !== null" :title="$t('alert.success')" active="true" class="mb-5" color="success">
      {{ success }}
    </vs-alert>

    <vs-alert v-if="error != null" :title="$t('alert.error')" active="true" class="mb-5" color="danger">
      {{ error }}
    </vs-alert>
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
      <div class="vx-col w-full sm:w-1/1 md:w-1/2 mb-base">
        <b-card v-if="needNewToken" :title="$t('subscription.card_title_new_account')">
          <vs-row>
            <vs-col>
              <form id="billing-form" @submit.prevent="sendToRecurly">
                <vs-row>
                  <vs-col vs-w="12">
                    <vs-alert
                      v-if="fieldErrors.number || fieldErrors.month || fieldErrors.year"
                      class="mb-2 mt-2"
                      color="danger"
                    >
                      <div v-if="fieldErrors.number">
                        {{ $t('subscription.validation.number') }}
                      </div>
                      <div v-if="fieldErrors.month">
                        {{ $t('subscription.validation.month') }}
                      </div>
                      <div v-if="fieldErrors.year">
                        {{ $t('subscription.validation.year') }}
                      </div>
                    </vs-alert>

                    <div id="recurly-elements">
                      <!-- Recurly Elements will be attached here -->
                    </div>
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col vs-w="12" class="mt-2">
                    <div class="flex" style="justify-content: flex-end">
                      <img src="@/assets/images/cards/_card1.png">
                      <img src="@/assets/images/cards/_card2.png">
                      <img src="@/assets/images/cards/_card3.png">
                      <img src="@/assets/images/cards/_card4.png">
                      <img src="@/assets/images/cards/_card5.png">
                    </div>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-input
                      v-model="customer.first_name"
                      :danger="!!fieldErrors.first_name"
                      :danger-text="$t('subscription.validation.first_name')"
                      :label="$t('subscription.first_name')"
                      data-recurly="first_name"
                      style="width: 100%"
                      type="text"
                    />
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-input
                      v-model="customer.last_name"
                      :danger="!!fieldErrors.last_name"
                      :danger-text="$t('subscription.validation.last_name')"
                      :label="$t('subscription.last_name')"
                      data-recurly="last_name"
                      style="width: 100%"
                      type="text"
                    />
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-input
                      v-model="customer.address1"
                      :danger="!!fieldErrors.address1"
                      :danger-text="$t('subscription.validation.address1')"
                      :label="$t('subscription.address1')"
                      data-recurly="address1"
                      style="width: 100%"
                      type="text"
                    />
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-input
                      v-model="customer.city"
                      :danger="!!fieldErrors.city"
                      :danger-text="$t('subscription.validation.city')"
                      :label="$t('subscription.city')"
                      data-recurly="city"
                      style="width: 100%"
                      type="text"
                    />
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-select
                      v-model="customer.country"
                      class="w-full"
                      :danger="!!fieldErrors.country"
                      :danger-text="$t('subscription.validation.country')"
                      :label="$t('subscription.country')"
                    >
                      <vs-select-item
                        v-for="country in countries"
                        :key="country.name"
                        :value="country['alpha-2']"
                        :text="country.name"
                      />
                    </vs-select>
                    <input
                      v-model="customer.country"
                      type="hidden"
                      data-recurly="country"
                    >
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-input
                      v-model="customer.postal_code"
                      :danger="!!fieldErrors.postal_code"
                      :danger-text="$t('subscription.validation.postal_code')"
                      :label="$t('subscription.postal_code')"
                      data-recurly="postal_code"
                      style="width: 100%"
                      type="text"
                    />
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-input
                      v-model="customer.company_name"
                      :danger="!!fieldErrors.company_name"
                      :danger-text="$t('subscription.validation.company_name')"
                      :label="$t('subscription.company_name')"
                      data-recurly="company"
                      style="width: 100%"
                      type="text"
                    />
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col class="mt-5" vs-w="12">
                    <vs-input
                      v-model="customer.vat_number"
                      :danger="!!fieldErrors.vat_number"
                      :danger-text="$t('subscription.validation.vat_number')"
                      :label="$t('subscription.vat_number')"
                      data-recurly="vat_number"
                      style="width: 100%"
                      type="text"
                    />
                  </vs-col>
                </vs-row>
                <div class="mt-5">
                  <!-- Recurly.js will update this field automatically -->
                  <input data-recurly="token" name="recurly-token" type="hidden">
                  <vs-button class="mr-3 mb-2" @click="sendToRecurly">
                    {{ $t('subscription.save_card') }}
                  </vs-button>
                </div>
              </form>

            </vs-col>
          </vs-row>
        </b-card>
        <b-card v-else-if="needSubscription" class="text-center bg-warning-gradient greet-user">
          <vs-row>
            <vs-col>
              <feather-icon
                icon="XIcon"
                class="p-6 mb-8 bg-warning inline-flex rounded-full shadow"
                svg-classes="h-8 w-8"
              />
              <h1 class="mb-6">
                {{ $t('subscription.need_subscription') }}
              </h1>
              <div class="mt-5">
                <vs-button class="mr-3 mb-2" @click="activateSubscription">
                  {{ $t('subscription.activate') }}
                </vs-button>
              </div>
            </vs-col>
          </vs-row>
        </b-card>
        <b-card v-else class="text-center bg-success-gradient greet-user">
          <vs-row>
            <vs-col>
              <feather-icon
                icon="CheckIcon"
                class="mb-5 bg-success inline-flex rounded-full shadow text-white"
                size="48"
                svg-classes="h-8 w-8"
              />
              <h1 class="mb-4">
                {{ $t('subscription.subscription_active') }}
              </h1>
              <template v-if="endsAt">
                <h4>{{ $t('subscription.ends_at') }}</h4>
                <p class="mb-4">
                  {{ endsAt }}
                </p>
              </template>
            </vs-col>
          </vs-row>
        </b-card>
      </div>
    </b-overlay>
    <div class="vx-col w-full sm:w-1/1 md:w-1/2 mb-base">
      <b-card class="text-center bg-primary-gradient greet-user">
        <feather-icon
          icon="SearchIcon"
          class="mb-5 bg-primary inline-flex rounded-full text-white shadow"
          size="48"
          svg-classes="h-8 w-8"
        />
        <h1 class="mb-4">
          {{ $t('subscription.offer.search_shoptet.title') }}
        </h1>
        <p class="mx-auto">
          {{ $t('subscription.offer.search_shoptet.subtitle') }}
        </p>

        <div class="mt-10">
          <h5
            v-for="feature in $t('subscription.offer.search_shoptet.features')"
            :key="feature"
            class="mt-2 text-left"
          >
            <feather-icon
              icon="CheckIcon"
              class="bg-success inline-flex rounded-full text-white shadow"
              size="32"
              svg-classes="h-5 w-5"
            />
            {{ feature }}
          </h5>
        </div>
        <h1 class="mt-3">
          €25 / mc
        </h1>
        <small>{{ $t('subscription.offer.netto') }}</small>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from '@/apiRequest'
import { ValidationProvider } from 'vee-validate'
import moment from 'moment'
import countries from '@/resources/data/countries.json'

export default {
  components: {
    ValidationProvider,
  },
  data() {
    return {
      customer: {
        first_name: '',
        last_name: '',
        address1: '',
        city: '',
        country: '',
        postal_code: '',
        company_name: '',
        vat_number: '',
      },
      loaded: false,
      subscription: {
        account_code: null,
        has_account: true,
        is_active: true,
        has_subscription: true,
        ends_at: null,
      },
      success: null,
      error: null,
      errors: [],
      elements: null,
      token: null,
      countries,
      dataLoading: true,
    }
  },
  computed: {
    needNewToken() {
      return !this.subscription.has_account || !this.subscription.is_active
    },
    needSubscription() {
      return !this.subscription.has_subscription
    },
    endsAt() {
      return moment.utc(this.subscription.ends_at).local().format('YYYY-MM-DD hh:mm:ss')
    },
    fieldErrors() {
      const errors = {}

      for (let i = 0; i < this.errors.length; i++) {
        const error = this.errors[i]
        errors[error.field] = error.messages.join(', ')
      }
      return errors
    },
  },
  mounted() {
    // eslint-disable-next-line
    this.elements = recurly.Elements()

    this.loadSubscription()
  },
  methods: {
    createSubscription(token) {
      this.error = null
      this.errors = []
      this.success = null
      this.dataLoading = true

      axios.post('/search/subscription', {
        token,
        first_name: this.customer.first_name,
        last_name: this.customer.last_name,
      }).then(result => {
        this.subscription = result.data
        this.dataLoading = false
      }).catch(e => {
        this.dataLoading = false
        this.error = e.response.data.error
        this.errors = []
      })
    },
    activateSubscription() {
      this.error = null
      this.success = null
      this.dataLoading = true

      axios.post('/search/subscription/activate').then(result => {
        this.subscription = result.data
        this.dataLoading = false
      }).catch(e => {
        console.error(e)
        this.dataLoading = false
        this.error = this.$t('search.settings.errorGet')
      })
    },
    loadSubscription() {
      this.error = null
      this.success = null
      this.dataLoading = true

      axios.get('/search/subscription').then(result => {
        this.subscription = result.data
        this.loaded = true

        if (this.needNewToken) {
          this.$nextTick(() => {
            this.initBillingForm()
          })
        }
        this.dataLoading = false
      }).catch(e => {
        console.error(e)
        this.dataLoading = false
        this.error = this.$t('search.settings.errorGet')
      })
    },
    initBillingForm() {
      const cardElement = this.elements.CardElement()
      cardElement.attach('#recurly-elements')
    },
    sendToRecurly() {
      this.dataLoading = true

      // eslint-disable-next-line
        recurly.token(this.elements, document.getElementById('billing-form'), (err, token) => {
        this.dataLoading = false

        if (err) {
          this.error = err.message
          console.error(err)
          this.errors = err.details
        } else {
          // recurly.js has filled in the 'token' field, so now we can submit the
          // form to your server
          this.createSubscription(token.id)
        }
      })
    },
  },
}
</script>

<style>
  .recurly-element.recurly-element-card {
    height: 3rem;
  }
</style>
